import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Row, Col, ProductBookCard, Button, WhatNext } from '../../components/global'
import Image from '../../components/image'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const FirstThingsFirst = () => (
  <Layout>
    <SEO title="First Things First Flip Charts" />
    <div className="container container-last">
      <h1>
        <em>First Things First</em>
        <br />
        Flip Charts
      </h1>
      <p className="boost">
        <em>FIRST THINGS FIRST</em> is an auto/truck electrical system troubleshooting flip chart
        that focuses attention on possible problems in the primary electrical system, consisting of
        battery condition, major ground connections and generator (alternator) charging system
        performance.
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="<em>FIRST THINGS FIRST</em>"
            step="Flip Chart"
            animate={false}
            price={89.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p>
            These flip charts guide a technician through quick and simple electrical tests to
            identify common battery problems, issues with corrosion in the engine and accessory
            grounds as well as verifying the charging system IS or is NOT performing properly.
          </p>
          <p className="boost">Two laminated flip chart versions are available!</p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=04475a5ffa06649e79142588b07825e7"
              text="Buy <em>FIRST THINGS FIRST</em>-Pro $89.00 + shipping"
              type="secondary"
            />{' '}
            <span className="meta button-meta">
              (Single-Battery Electrical System 12V-14V) Printed in black ink and laminated to hold
              up in heavy shop use Professional version - 14 Test Steps - for $89.00 per copy.
            </span>
          </p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=5b39267ff5954d4eb4e07ca6ce27c111"
              text="Buy <em>FIRST THINGS FIRST</em>-2 $99.00 + shipping"
              type="secondary"
            />
            <span className="meta button-meta">
              (Dual-Battery Electrical System 12V-14V) Printed in black ink and laminated to hold up
              in heavy shop use Professional version - 18 Test Steps - for $99.00 per copy.
            </span>
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">
        Don't waste time, labor, and money replacing working parts and not solving the underlying
        electrical problem.
      </p>
      <p>
        Every vehicle that comes in the shop has at least one battery, two major ground circuits and
        a generator (alternator) charging system that operates all electrical and electronic
        circuits on the vehicle.{' '}
        <strong>
          A component failure or a bad connection in one of these essential electrical circuits
          could have a profoundly negative affect on a vehicle's overall electrical and engine
          performance.
        </strong>
      </p>
      <p>
        It's easy to test a vehicle's primary electrical system when you follow along with the
        step-by-step instructions in FIRST THINGS FIRST. Each test step is on a laminated cardboard
        page fully explained and illustrated. Instructions on the left side of the page and an
        illustration on the right side showing you how to place the DMM test leads.
      </p>
      <p>
        <em>FIRST THINGS FIRST</em> comes in two versions:
      </p>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <em>FIRST THINGS FIRST</em>-Pro
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              The "Pro" version is for the most common single battery 14V electrical system with the
              primary electrical system connections clearly shown.
            </p>
            <p>
              Below is the illustration used in <em>FIRST THINGS FIRST</em>-Pro for each of the 14
              test steps.
            </p>
            <Image file="FTF-Pro_2016_Figure.jpg" />
            <p>
              The DMM test leads are shown connected to the vehicle for each test measurement step
              which is explained and the correct DMM reading is shown in the DMM. The text
              explaining each measurement tells you what to do if the DMM reading is too high or too
              low.
            </p>
            <p>The 14 test steps can be completed in less than 5 minutes with a little practice.</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <em>FIRST THINGS FIRST</em>-2
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              This is a dual battery 14V electrical system version with the primary electrical
              system connections clearly shown.
            </p>
            <p>
              Below is the illustration used in <em>FIRST THINGS FIRST</em>-2 for each test step.
              The DMM test leads are shown connected to the vehicle for each test measurement step
              which is explained and the correct DMM reading is shown in the DMM.
            </p>
            <Image file="FTF-2_2016_Figure.jpg" />
            <p>
              The text explaining each test measurement tells you what to do if the DMM reading is
              too high or too low.
            </p>
            <p>The 18 test steps can be completed in less than 5 minutes with a little practice.</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <p className="text-center boost mts">
        One electrical problem found with a <em>FIRST THINGS FIRST</em> flip-Chart will pay for
        itself!
      </p>
      <hr />
      <WhatNext />
    </div>
  </Layout>
)

export default FirstThingsFirst
